
//本地引入
import { Component, Vue } from "vue-property-decorator";

//公共库
import GFunc from "@/utils/gFunc";
import { countryNoComList, parseTime } from "@/utils";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { getLotteryActivity, updataLotteryActivity } from "@/api/request/activity";

//组件
@Component({
  name: "RechargeLottery",
})

//组件函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //活动列表
  private countryList: any[] = []; //归属国家列表
  private dialogStatus: string = ""; //对话框状态
  private listLoading: boolean = false; // 列表加载
  private dialogLoading: boolean = false; //对话框加载
  private addDialogVisible: boolean = false; //添加对话框

  //目标数据
  private tags1: any = []; //发放目标
  private tags2: any = []; //发放目标
  private tags3: any = []; //发放目标
  private tags4: any = []; //发放目标
  private tagInput1: string = ""; //添加ID文本1
  private tagInput2: string = ""; //添加ID文本2
  private tagInput3: string = ""; //添加ID文本3
  private tagInput4: string = ""; //添加ID文本4

  //添加表格
  private addForm: any = {
    //数据赋值
    data: {
      //配置数据
      id: undefined, //ID
      ext: "", //参数
      flag: "", //标识
      body: "", //详情
      title: "", //标题
      country: "", //归属国家
      liveurl: "", //直播地址
      winning_details: "", //中奖详情

      //状态数据
      status: 0, //状态(0:关闭,1:开启)
      type: 1, //类型(1:外部地址跳转,2:App内部跳转)

      //图片参数
      cover: "", //封面图片
      pictures: "", //海报图片

      //时间参数
      end_time: "", //活动结束时间
      live_time: "", //直播开始时间
      begin_time: "", //活动开始时间
    },

    //临时数据
    time: [],
    type: "1",
    status: "0",
    pictures: [],
  };

  //创建时调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //添加ID1
  private addTag1(): void {
    //数据处理
    if (/^[1-9]\d*$/.test(this.tagInput1.trim()) && this.tagInput1.trim() !== "") {
      this.tags1.push(this.tagInput1.trim());
      this.tagInput1 = ""; // 清空输入框
    }
    //异常处理
    else {
      this.$message.error("请输入正确形式的ID");
      this.tagInput1 = "";
    }
  }

  //添加ID2
  private addTag2(): void {
    //数据处理
    if (/^[1-9]\d*$/.test(this.tagInput2.trim()) && this.tagInput2.trim() !== "") {
      this.tags2.push(this.tagInput2.trim());
      this.tagInput2 = ""; // 清空输入框
    }
    //异常处理
    else {
      this.$message.error("请输入正确形式的ID");
      this.tagInput1 = "";
    }
  }

  //添加ID3
  private addTag3(): void {
    //数据处理
    if (/^[1-9]\d*$/.test(this.tagInput3.trim()) && this.tagInput3.trim() !== "") {
      this.tags3.push(this.tagInput3.trim());
      this.tagInput3 = ""; // 清空输入框
    }
    //异常处理
    else {
      this.$message.error("请输入正确形式的ID");
      this.tagInput3 = "";
    }
  }

  //添加ID4
  private addTag4(): void {
    //数据处理
    if (/^[1-9]\d*$/.test(this.tagInput4.trim()) && this.tagInput4.trim() !== "") {
      this.tags4.push(this.tagInput4.trim());
      this.tagInput4 = ""; // 清空输入框
    }
    //异常处理
    else {
      this.$message.error("请输入正确形式的ID");
      this.tagInput4 = "";
    }
  }

  //获取活动列表
  private async getList() {
    //显示加载
    this.listLoading = true;

    //获取活动列表
    const { data } = await getLotteryActivity({});

    //数据赋值
    this.list = data.result;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.addDialogVisible = false;
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //创建道具
  private handleCreate(): void {
    //数据赋值
    this.dialogStatus = "create";

    //初始化数据
    this.addForm = {
      //数据赋值
      data: {
        //配置数据
        id: undefined, //ID
        ext: "", //参数
        flag: "", //标识
        body: "", //详情
        title: "", //标题
        country: "",
        liveurl: "", //直播地址
        winning_details: "", //中奖详情

        //状态数据
        status: 0, //状态(0:关闭,1:开启)
        type: 1, //类型(1:外部地址跳转,2:App内部跳转)

        //图片参数
        cover: "", //封面图片
        pictures: "", //海报图片

        //时间参数
        end_time: "", //活动结束时间
        live_time: "", //直播开始时间
        begin_time: "", //活动开始时间
      },

      //临时数据
      time: [],
      type: "1",
      status: "0",
      pictures: [], //海报图片
    };
    this.tags1 = []; //发放目标
    this.tags2 = []; //发放目标
    this.tags3 = []; //发放目标
    this.tags4 = []; //发放目标
    this.tagInput1 = ""; //添加ID文本1
    this.tagInput2 = ""; //添加ID文本2
    this.tagInput3 = ""; //添加ID文本3
    this.tagInput4 = ""; //添加ID文本4
    this.coverPicFileList = []; //封面图片文件
    this.picturesFileList = []; //海报图片文件

    //显示对话框
    this.addDialogVisible = true;
  }

  //处理创建
  private async createHandle() {
    //信息补充
    if (this.addForm.data.title == "") return this.$message.error("请输入活动标题");
    if (this.addForm.time.length == 0) return this.$message.error("请选择活动时间");
    if (this.addForm.type == "0" && this.addForm.data.ext == "") return this.$message.error("请输入跳转参数");
    if (this.addForm.type == "1" && this.addForm.data.liveurl == "") return this.$message.error("请输入跳转地址");

    //显示加载
    this.dialogLoading = true;

    //数据赋值
    this.addForm.data.type = Number(this.addForm.type);
    this.addForm.data.status = Number(this.addForm.status);
    this.addForm.data.end_time = parseTime(this.addForm.time[1]);
    this.addForm.data.begin_time = parseTime(this.addForm.time[0]);
    this.addForm.data.pictures = JSON.stringify(this.addForm.pictures);
    this.addForm.data.live_time = parseTime(this.addForm.data.live_time);

    //中奖详情
    var winDetail: any = {};
    if (this.tags1.length > 0) {
      winDetail["1"] = [];
      for (var i: number = 0; i < this.tags1.length; i++) {
        winDetail["1"].push(Number(this.tags1[i]));
      }
    }
    if (this.tags2.length > 0) {
      winDetail["2"] = [];
      for (var i: number = 0; i < this.tags2.length; i++) {
        winDetail["2"].push(Number(this.tags2[i]));
      }
    }
    if (this.tags3.length > 0) {
      winDetail["3"] = [];
      for (var i: number = 0; i < this.tags3.length; i++) {
        winDetail["3"].push(Number(this.tags3[i]));
      }
    }
    if (this.tags4.length > 0) {
      winDetail["4"] = [];
      for (var i: number = 0; i < this.tags4.length; i++) {
        winDetail["4"].push(Number(this.tags4[i]));
      }
    }
    this.addForm.data.winning_details = JSON.stringify(winDetail);

    //创建
    const { data } = await updataLotteryActivity(this.addForm.data);

    //隐藏对话框
    this.addDialogVisible = false;

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;
    }, 0.5 * 1000);

    //获取列表
    this.getList();
  }

  //处理更新
  private async updataHandle() {
    //信息补充
    if (this.addForm.data.title == "") return this.$message.error("请输入活动标题");
    if (this.addForm.time.length == 0) return this.$message.error("请选择活动时间");
    if (this.addForm.data.live_time == "") return this.$message.error("请选择直播时间");
    if (this.addForm.type == "0" && this.addForm.data.ext == "") return this.$message.error("请输入跳转参数");
    if (this.addForm.type == "1" && this.addForm.data.liveurl == "") return this.$message.error("请输入跳转地址");

    //数据赋值
    this.addForm.data.type = Number(this.addForm.type);
    this.addForm.data.status = Number(this.addForm.status);
    this.addForm.data.end_time = parseTime(this.addForm.time[1]);
    this.addForm.data.begin_time = parseTime(this.addForm.time[0]);
    this.addForm.data.pictures = JSON.stringify(this.addForm.pictures);
    this.addForm.data.live_time = parseTime(this.addForm.data.live_time);

    //中奖详情
    var winDetail: any = {};
    if (this.tags1.length > 0) {
      winDetail["1"] = [];
      for (var i: number = 0; i < this.tags1.length; i++) {
        winDetail["1"].push(Number(this.tags1[i]));
      }
    }
    if (this.tags2.length > 0) {
      winDetail["2"] = [];
      for (var i: number = 0; i < this.tags2.length; i++) {
        winDetail["2"].push(Number(this.tags2[i]));
      }
    }
    if (this.tags3.length > 0) {
      winDetail["3"] = [];
      for (var i: number = 0; i < this.tags3.length; i++) {
        winDetail["3"].push(Number(this.tags3[i]));
      }
    }
    if (this.tags4.length > 0) {
      winDetail["4"] = [];
      for (var i: number = 0; i < this.tags4.length; i++) {
        winDetail["4"].push(Number(this.tags4[i]));
      }
    }
    this.addForm.data.winning_details = JSON.stringify(winDetail);

    //创建
    const { data } = await updataLotteryActivity(this.addForm.data);

    //隐藏对话框
    this.addDialogVisible = false;

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;
    }, 0.5 * 1000);

    //获取列表
    this.getList();
  }

  //移除目标用户
  private removeTag1(index: any): void {
    this.tags1.splice(index, 1);
  }

  //移除目标用户
  private removeTag2(index: any): void {
    this.tags2.splice(index, 1);
  }

  //移除目标用户
  private removeTag3(index: any): void {
    this.tags3.splice(index, 1);
  }

  //移除目标用户
  private removeTag4(index: any): void {
    this.tags4.splice(index, 1);
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.dialogStatus = "updata";

    //初始化数据
    this.addForm = {
      //数据赋值
      data: GFunc.deepClone(row),

      //临时数据
      type: String(row.type),
      status: String(row.status),
      pictures: JSON.parse(row.pictures),
      time: [row.begin_time, row.end_time],
    };
    this.tagInput1 = ""; //添加ID文本1
    this.tagInput2 = ""; //添加ID文本2
    this.tagInput3 = ""; //添加ID文本3
    this.tagInput4 = ""; //添加ID文本4
    this.coverPicFileList = [{ url: row.cover }]; //封面图片文件
    for (var i: number = 0; i < this.addForm.pictures.length; i++) {
      this.picturesFileList.push({ url: this.addForm.pictures[i] }); //海报图片文件
    }
    this.tags1 = JSON.parse(row.winning_details)["1"] ? JSON.parse(row.winning_details)["1"] : []; //发放目标
    this.tags2 = JSON.parse(row.winning_details)["2"] ? JSON.parse(row.winning_details)["2"] : []; //发放目标
    this.tags3 = JSON.parse(row.winning_details)["3"] ? JSON.parse(row.winning_details)["3"] : []; //发放目标
    this.tags4 = JSON.parse(row.winning_details)["4"] ? JSON.parse(row.winning_details)["4"] : []; //发放目标

    //显示对话框
    this.addDialogVisible = true;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //中奖详情
  private getWinDetail(winDetailStr: string, index: number): string {
    //中奖详情
    var detail: string = "";
    var winDetail: any = JSON.parse(winDetailStr);

    //数据赋值
    if (winDetail[String(index)]) {
      for (var i: number = 0; i < winDetail[String(index)].length; i++) {
        detail += winDetail[String(index)][i] + ",";
      }
      detail = detail.substring(0, detail.length - 1);
    }

    //返回详情
    return detail;
  }

  //---------------------------- 图片上传 ----------------------------
  //图片变量
  private getHeads = getHeaders(); //获取抬头
  private getAction = this.getApi(); //获取API
  private coverPicFileList: any[] = []; //封面图片文件
  private picturesFileList: any[] = []; //海报图片文件

  //获取APi
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //图片图片上传
  private handlePicBeforeUploadCommon(file: any) {
    //定义配置
    const index = file.name.lastIndexOf(".");
    const isLt2M = file.size / 1024 / 1024 < 5;
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];

    //资源过大
    if (!isLt2M) {
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出5M",
        type: "warning",
        duration: 2000,
      });
      return false;
    }
    //格式不支持
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除封面图片
  private handleCoverPicRemove(file: any, fileList: any): void {
    //清空数据
    this.addForm.data.cover = "";
    this.coverPicFileList = fileList;
  }

  //移除海报图片
  private handlePicturesRemove(file: any, fileList: any): void {
    //数据赋值
    this.picturesFileList = fileList;

    //数据赋值
    var url: string = file.response.data.images[0];
    var index: number = this.addForm.pictures.indexOf(url);
    this.addForm.pictures.splice(index, 1);
  }

  //封面图片上传成功
  private handleCoverPicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.coverPicFileList = fileList;
    this.addForm.data.cover = response.data.images[0];
  }

  //海报片上传成功
  private handlePicturesUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picturesFileList = fileList;
    this.addForm.pictures.push(response.data.images[0]);
  }

  //---------------------------- 图片加载 ----------------------------
  //定义变量
  private dialogVisible = false; //显示状态
  private dialogImageUrl: any = null; //图片地址

  //点击已上传图片
  private handlePreviewCommon(file: any) {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示界面
    this.dialogVisible = true;
  }
}
